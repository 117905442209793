@charset "utf-8";

@import url("normalize.css/normalize.css");


/* ==========================================================================
   Типографика
   ========================================================================== */

@font-face {
    font-family: "Proxima Nova";
    src: url("../fonts/ProximaNova-Bold.woff2") format("woff2"),
        url("../fonts/ProximaNova-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova";
    src: url("../fonts/ProximaNova-Semibold.woff2") format("woff2"),
        url("../fonts/ProximaNova-Semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "Noto Sans";
    src: url("../fonts/NotoSans-BoldItalic.woff2") format("woff2"),
        url("../fonts/NotoSans-BoldItalic.woff") format("woff");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Noto Sans";
    src: url("../fonts/NotoSans-Bold.woff2") format("woff2"),
        url("../fonts/NotoSans-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Sans";
    src: url("../fonts/NotoSans.woff2") format("woff2"),
        url("../fonts/NotoSans.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Noto Sans";
    src: url("../fonts/NotoSans-Italic.woff2") format("woff2"),
        url("../fonts/NotoSans-Italic.woff") format("woff");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

html {
    font-size: 100%;
    font-family: -apple-system, BlinkMacSystemFont, Helvetica, Arial, sans-serif;
    text-rendering: optimizeLegibility;
    font-smooth: always;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
    color: #333;
    background-color: #fff;
}

    .fonts-loaded {
        font-family: "Noto Sans", sans-serif
    }


/* ==========================================================================
   Базовые элементы
   ========================================================================== */

/**
 * SVG-спрайт
 */
.svg-defs {
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 1px;
    opacity: 0;
}

.icon {
    display: inline-block;
    vertical-align: middle;
}

    .icon_about {
        width: 70px;
        height: 60px;
    }

    .icon_services {
        width: 75px;
        height: 60px;
    }

    .icon_solutions {
        width: 81px;
        height: 61px;
    }

    .icon_team {
        width: 67px;
        height: 60px;
    }

/**
 * Кнопки
 */
.button {
    display: inline-block;
    vertical-align: middle;
    box-sizing: border-box;
    max-width: 100%;
    padding: 15px;
    border: 2px solid;
    border-radius: 9999px;
    text-decoration: none;
    line-height: 1;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    color: #ff4628;
    background: #fff;
    transition: .2s;
    cursor: pointer;
}

    .button__text {
        display: inline-block;
        padding-left: 0.097em;
        letter-spacing: 0.097em;
    }

    .button:hover {
        color: #fff;
        border-color: #ff4628;
        background-color: #ff4628;
    }
    
    .button_full {
        width: 100%;
    }

/**
 * Поля ввода
 */
.text-input {
    box-sizing: border-box;
    background: 0;
    border: 0;
    max-width: 100%;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    color: inherit;
}

    .text-input:focus {
        outline: 0;
        box-shadow: 0 1px 0 #ccc;
    }

    textarea.text-input {
        min-height: 135px;
        padding-top: 10px;
        padding-bottom: 10px;
    }

/**
 * Группировка метки и поля
 */
.field {
    position: relative;
    display: block;
}

    .field__label {
        position: absolute;
        top: 0; 
        left: 0;
        display: block;
        letter-spacing: 0.01em;
        text-transform: uppercase;
        font-size: 14px;
        line-height: 1;
        font-weight: bold;
        transition: .2s;
    }

    .field__input {
        width: 100%;
        height: 46px;
    }

    .field_focus .field__label,
    .field_filled .field__label {
        transform: translateY(-100%);
        font-size: 12px;
        color: #999;
    }

    .field_error {
        /*border-bottom-color: #ff4628;
        box-shadow: 0 1px #ff4628;*/
    }

    .field_error .field__input {
        color: #ff4628;
        border-bottom-color: #ff4628;
        box-shadow: 0 1px #ff4628;
    }

/**
 * Основное лого
 */
.logo {
    display: block;
    color: inherit;
    text-decoration: none;
    padding-left: 44px;
    line-height: 1;
    font-weight: normal;
    background: url("../img/logo.svg") no-repeat left 0 bottom 2px;
    background-size: 33px 55px;
}

    .logo__big {
        display: block;
        font-size: 41px;
    }

    .logo__small {
        display: block;
        font-size: 19px;
        letter-spacing: 0.097em;
        padding-left: 0.097em;
        color: #f84d27;
    }

/**
 * Блок с контактами
 */
/* телефон */
.contacts-tel {
    position: relative;
    display: inline-block;
    vertical-align: top;
    font-size: 18px;
    font-weight: bold;
    letter-spacing: 0.097em;
    text-decoration: none;
    color: inherit;
}

    .contacts-tel::after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        top: 100%;
        height: 1px;
        opacity: 0.5;
        transform: scaleX(0);
        transform-origin: 100% 50%;
        background-color: #fe4d3d;
        transition: transform .4s ease-in-out;
    }

    .contacts-tel:hover::after {
        transform: scaleX(1);
        transform-origin: 0% 50%;
    }

/* email */
.contacts-mail {
    margin-bottom: 5px;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: #fe4d3d;
    font-size: 14px;
}

    .contacts-mail:hover {
        text-decoration: underline;
    }

/**
 * Основной заголовок
 */
.main-title {
    margin: 0;
    position: relative;
    font-size: 20px;
    line-height: 1.4;
    /*font-family: "Proxima Nova", "Noto Sans", sans-serif;*/
}

    .fonts-loaded .main-title {
        font-family: "Proxima Nova", "Noto Sans", sans-serif;
    }

    .main-title__part {
        display: block;
    }

    @media (min-width: 960px) {
        .main-title {
            font-size: 36px;
            line-height: 1.2;
            padding-bottom: 30px;
        }

        .main-title::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            height: 3px;
            width: 114px;
            background: #ff4628;
        }
    }

/**
 * Блок с призывом к действию
 */
.cta {}

    .cta__text {
        margin-bottom: 18px;
        display: none;
        font-size: 14px;
        line-height: 1.43;
        color: #666;
    }

    @media (min-width: 720px ) {
        .cta__text {
            display: block;
        }
    }

/**
 * Модальные окна
 */
.modal {
    position: fixed;
    z-index: 9;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    visibility: hidden;
    /*padding-top: 48px;*/
    /*padding-bottom: 48px;*/
    background: #fff;
    transition: .4s ease-in-out;
}

    .modal__tools {
        position: relative;
        z-index: 1;
    }
    
    .modal__close {
        position: absolute;
        top: 40px;
        right: 20px;
        font-size: 55px;
        width:  1em;
        height: 1em;
        border: 0;
        background: 0;
        cursor: pointer;
    }
    
        .modal__close::before,
        .modal__close::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            height: 2px;
            width: 1em;
            background: currentColor;
            transition: .2s;
        }

            .modal__close::before {
                transform: rotate(45deg);
            }

            .modal__close::after {
                transform: rotate(-45deg);
            }

        .modal__close:hover {
            color: #ff4628;
        }

    .modal__inner {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        overflow: hidden;
        padding-top: 100px;
        padding-bottom: 100px;
    }

    .modal_open {
        opacity: 1;
        visibility: visible;
    }

        .modal_open .modal__inner {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
        }

/* ==========================================================================
   Структурные элементы
   ========================================================================== */
   
html {
    height: 100%;
}

body {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    height: 100vh; /*#23242*/
}

    body > * {
        flex: 0 0 auto;
    }

/**
 * Ограничитель ширины
 */
.main-container {
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    max-width: 1170px;
}

/**
 * Очистка float
 */
.clearfix {}

    .clearfix::after {
        content: "";
        display: table;
        clear: both;
    }


/**
 * Контейнер под шапку и меню
 */
.head-section {
    position: relative;
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
}

    .head-section > * {
        flex: 0 0 auto;
        width: 100%;
    }

.waves {
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;
    opacity: 0.6;
    pointer-events: none;
}

    .waves::before,
    .waves::after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 50%;
        background: no-repeat 50% 100%;
        animation: waves 7s linear infinite;
    }

        @keyframes waves {
            0%, 50%, 100% {
                transform: translateX(0) translateY(0);
            }
            25% {
                transform: translateX(-10px) translateY(5px);
            }
            75% {
                transform: translateX(10px) translateY(-5px);
            }
        }

    .waves::after {
        /*bottom: -120px;*/
        margin-left: -999px;
        width: 1997px;
        height: 376px;
        background-image: url("../img/wave-front.svg");
        animation-direction: reverse;
    }

    .waves::before {
        /*bottom: -47px;*/
        margin-left: -991px;
        width: 1981px;
        height: 298px;
        background-image: url("../img/wave-back.svg");
    }

    @media (max-width: 780px) {
        .waves::before,
        .waves::after {
            bottom: -40px;
        }

        .waves::after {
            /*bottom: -120px;*/
            margin-left: -719px;
            width: 1438px;
            height: 267px;
            background-image: url("../img/wave-front-small.svg");
        }

        .waves::before {
            /*bottom: -47px;*/
            margin-left: -716px;
            width: 1431px;
            height: 212px;
            background-image: url("../img/wave-back-small.svg");
        }
    }


/**
 * Шапка
 */
.header {}

    @media (max-width: 780px) {
        .header {
            position: relative;
        }
    }

    .header__row {}

        .header__row_top {}

        .header__row_middle {}

    .header__logo {
        margin: 0;
        border-bottom: 1px solid #e2e2e2;
    }

    .header__logo {
        padding-top:    22px;
        padding-bottom: 23px;
    }

    .header__contacts {
        padding-top:    22px;
        padding-bottom: 20px;
        text-align: left;
    }

        @media (min-width: 920px) {
            .header__row_top {
                border-bottom: 1px solid #e2e2e2;
            }

            .header__logo {
                float: left;
                border-bottom: 0;
            }

            .header__contacts {
                margin-top: 14px;
                float: right;
                text-align: right;
            }

            .header__logo,
            .header__contacts {
                padding-top:    30px;
                padding-bottom: 29px;
            }
    }

    .header__main-title {
        margin-bottom: 23px;
    }

    .header__cta {
        max-width: 260px;
    }

        @media (min-width: 1060px) {

            .header__main-title {
                margin-bottom: 0;
                float: left;
            }
            .header__cta {
                float: right;
            }
        }

        @media (min-width: 920px) {
            .header__row_middle {
                padding-top: 74px;
            }
            
            .header__cta {
                margin-top: 10px;
            }
        }

/**
 * Меню
 */
.menu {
    /*padding-top: 100px;*/
    /*padding-bottom: 77px;*/
    margin-top: auto;
    margin-bottom: auto;
    padding-top: 32px;
    position: relative;
    text-align: center;
}

.menu-list {
    list-style-type: none;
    font-size: 0;
}

    .menu-list__item {
        display: inline-block;
        vertical-align: top;
        width: 25%;
        font-size: 1rem;
    }

.menu-item {
    display: block;
    text-decoration: none;
    text-align: left;
}

    .menu-item__head {
        position: relative;
        animation: showMenuItem .5s both;
    }
    
        .menu-list__item:nth-child(1) .menu-item__head { animation-delay: 0.3s; }
        .menu-list__item:nth-child(2) .menu-item__head { animation-delay: 0.6s; }
        .menu-list__item:nth-child(3) .menu-item__head { animation-delay: 0.9s; }
        .menu-list__item:nth-child(4) .menu-item__head { animation-delay: 1.2s; }
        
        @keyframes showMenuItem {
            from {
                opacity: 0;
                transform:scale(.75)
            }
        }

        .menu-item__head::after {
            content: "";
            position: absolute;
            left: 0;
            bottom: 0;
            opacity: 0;
            width: 0;
            height: 0;
            border: 18px solid transparent;
            border-bottom-color: #fe4d3d;
            border-top: 0;
        }

    .menu-item__icon {
        margin-bottom: 13px;
        position: relative;
        height: 77px;
        transform: translateZ(0);
        color: #999;
    }

        .menu-item__icon .icon {
            position: absolute;
            left: 0;
            bottom: 0;
        }

    .menu-item__title {
        margin: 0;
        padding-bottom: 32px;
        text-transform: uppercase;
        font-weight: bold;
        letter-spacing: 0.097em;
        padding-left: 0.097em;
        color: #333;
    }

        .menu-item__title h2{
            font-size: 1em;
        }
    
    .menu-item__icon,
    .menu-item__title {
        transition: .2s;
    }

    .menu-item__body {
        position: absolute;
        z-index: 2;
        left: 0;
        right: 0;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        line-height: 1.5;
        color: #fff;
        background-color: #fe4d3d;
    }

    .menu-item__text {
        padding-top:    22px;
        padding-bottom: 25px;
    }

        .menu-item__text strong{
            font-weight: normal;
        }

    .menu-item:hover,
    .menu-item_active,
    .menu-item:hover .menu-item__icon,
    .menu-item:hover .menu-item__title,
    .menu-item_active .menu-item__icon,
    .menu-item_active .menu-item__title {
        color: #fe4d3d;
    }

    .menu-item__body,
    .menu-item__head::after {
        transform: translateY(10px);
        transition: .4s ease-in-out;
    }

    .menu-item_active .menu-item__body,
    .menu-item_active .menu-item__head::after {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
    .menu-item_active .menu-item__head::after {
        transition-delay: .2s;
    }
    
    @media (max-width: 780px) {
        .menu {
            padding-top: 26px;
            padding-bottom: 0;
        }

            .menu-list__item {
                width: 100%;
            }

        .menu-item {
            border-top: 1px solid #e2e2e2;
        }

        .menu-item__head {
            display: table;
            border-collapse: separate;
            border-spacing: 0;
            width: 100%;
            padding: 10px 0;
        }

            .menu-item__head::after {
                left: auto;
                right: -12px;
                /*transition-property: opaсity;*/
            }

            .menu-item__icon,
            .menu-item__title {
                display: table-cell;
                vertical-align: middle;
                padding: 0;
            }

            .menu-item__icon {
                width: 87px;
            }

                .menu-item__icon .icon {
                    position: static;
                }

            .menu-item__title {
                position: relative;
                padding: 0;
                padding-left: 16px;
            }

                .menu-item__title::before {
                    content: "";
                    position: relative;
                    top: 5px;
                    float: right;
                    transform: rotate(45deg);
                    width:  8px;
                    height: 8px;
                    border: 2px solid #999;
                    border-width: 2px 2px 0 0;
                    transition: .3s;
                }

                .menu-item_active .menu-item__title::before {
                    transform: rotate(135deg);
                    top: 1px;
                }

        .menu-item__body {
            position: relative;
            margin-left: -30px;
            margin-right: -30px;
            transform: translateY(0);
            opacity: 1;
            visibility: visible;
            height: 0;
            transition-property: height;
        }

            .menu-item_active .menu-item__body {
                height: auto;
            }
    }

/**
 * Карта
 */
.map-section {
    position: relative;
    flex: 1 1 200px;
    min-height: 200px;
}

    @media (max-width: 480px) {
        .map-section {
            min-height: 320px;
        }
    }

.map {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

/**
 * Подвал страницы
 */
.footer {
    padding-top:    8px;
    padding-bottom: 8px;
    font-size: 14px;
    line-height: 1.42;
    background: #d7d7d7;
}

    .footer__part {
        padding-top:    8px;
        padding-bottom: 8px;
        font-size: inherit;
    }

    .footer__copy {
        display: none;
    }

    .footer__address span {
        display: inline-block;
    }

        .footer__address span::after {
            content: "\00A0"
        }

    @media (min-width: 680px) {
        .footer {
            padding-top:    0px;
            padding-bottom: 0px;
        }
        
            .footer__part {
                padding-top:    6px;
                padding-bottom: 6px;
            }

            .footer__copy {
                float: left;
                display: block;
            }

            .footer__address {
                float: right;
        }
    }

/**
 * Форма отправки
 */
.send-mail-form {
    max-width: 800px;
    padding-right: 18px;
}

    .send-mail-form__title {
        margin: 0 0 54px;
        padding: 0;
        font-weight: bold;
        font-size: 60px;
        line-height: 1;
        font-size: 60px;
        font-weight: bold;
        line-height: 1
    }
    
        .fonts-loaded .send-mail-form__title,
        .fonts-loaded .send-mail-form__sub-title {
            font-family: "Proxima Nova", "Noto Sans", sans-serif;
            font-weight: 600;
        }
    
    .send-mail-form__sub-title {
        margin: 0 0 4px;
        padding: 0;
        font-size: 80px;
    }

    .send-mail-form .field {
        margin-bottom: 32px;
    }

    .send-mail-form__button {
        position: relative;
        width: 260px;
    }

    .send-mail-form__button::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 16px;
        opacity: 0;
        margin: auto;
        width:  20px;
        height: 20px;
        border: 2px solid #e2e2e2;
        border-top-color: #ff4628;
        border-radius: 50%;
        transition: .2s;
    }

    .send-mail-form__body {
        transition: .4s;
    }

    .send-mail-form__message {
        /*position: absolute;*/
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        visibility: hidden;
        max-width: 60%;
        padding-bottom: 45px;
        line-height: 1.42;
        transition: .4s;
    }
    
        .send-mail-form__message::after {
            content: "";
            position: absolute;
            left: 0;
            top: 100%;
            width: 114px;
            height: 2px;
            background: #ff4628;
        }

    .send-mail-form__req_start {
        position: relative;
    }
    
        .send-mail-form__req_start::after {
            content: "";
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    
        .send-mail-form__req_start .send-mail-form__button {
            pointer-events: none;
        }

            .send-mail-form__req_start .send-mail-form__button::before {
                left: 30px;
                opacity: 1;
                animation: showFormSpinner .75s linear infinite;
            }
        
        @keyframes showFormSpinner {
            to {
                transform: rotate(1turn);
            }
        }

    .send-mail-form__req_end {}
     
    .send-mail-form__req_success {}

       .send-mail-form__req_success .send-mail-form__message_success {
           opacity: 1;
           visibility: visible;
       }

    .send-mail-form__req_error {}

        .send-mail-form__req_error .send-mail-form__message_error,
        .send-mail-form__req_error-captcha .send-mail-form__message_error-captcha {
            opacity: 1;
            visibility: visible;
        }
    
    .send-mail-form__req_success .send-mail-form__body,
    .send-mail-form__req_error .send-mail-form__body,
    .send-mail-form__req_error-captcha .send-mail-form__body {
        opacity: 0;
        visibility: hidden;
    }
    
    @media (max-width: 640px) {
        .modal__inner {
            padding-top: 48px;
            padding-bottom: 48px;
        }

        .modal__close {
            top: 10px;
            right: 17px;
            font-size: 28px;
        }
        
        .send-mail-form__message {
            padding-bottom: 21px;
            font-size: 14px;
        }

        .send-mail-form__title {
            margin-bottom: 31px;
            font-size: 30px;
        }
        
        .send-mail-form__sub-title {
            font-size: 40px;
        }

        .send-mail-form .field {
            margin-bottom: 20px;
        }

        .send-mail-form .field__input {
            height: 32px;
            font-size: 13px;
        }

        .send-mail-form .field__label {
            font-size: 12px;
        }

        .send-mail-form .field_focus .field__label,
        .send-mail-form .field_filled .field__label {
            font-size: 10px;
        }
    }